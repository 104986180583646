<template>
	<div>
		<base-table :items="loadData" table-top paginate>
			<template #action>
				<excel-download-btn url="reports/project-list/export" file-name="project-list" />
			</template>
		</base-table>
	</div>
</template>

<script>
import BaseTable from '@/components/BaseTable.vue';
import api from '@/utils/api';
import { buildQueryString } from '@/utils/helpers';
import ExcelDownloadBtn from '@/components/ExcelDownloadBtn.vue';

export default {
	name: 'ProjectList',

	components: {
		BaseTable,
		ExcelDownloadBtn,
	},

	data: () => ({
		items: [],

		headers: [
			{ key: 'CT_PROJID', label: 'ID' },
			{ key: 'SP_TITLE', label: 'Name' },
			{ key: 'CT_PRICEUSD', label: 'Cost' },
			{ key: 'CT_FINALCOST', label: 'Final Cost' },
			{ key: 'CT_SDATE', label: 'Contract date' },
			{ key: 'CT_SITE_DELDATE', label: 'Delivery date' },
			{ key: 'CT_EDATE', label: 'End date' },
			{ key: 'CONSULTANT', label: 'Consultant' },
			{ key: 'CC_NAME', label: 'Contractor' },
			{ key: 'progress', label: 'progress' },
		],
	}),

	methods: {
		async loadData(ctx, searchQuery) {
			const path = `reports/project-list?${buildQueryString({ ...ctx, searchQuery })}`;
			const { data } = await api.call({ path, method: 'GET', noLoading: true });
			return data;
		},
	},
};
</script>

<style lang="scss" scoped></style>
